@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    section {
        @apply py-8 lg:py-24 lg:h-screen flex items-center;
    }
}

.blueGrand{
    background-image: linear-gradient(to top, #3e8bff, #c1a2fb);
    background-clip: text;
    color: transparent;
}  

.blueBackGroundGrand{
    background-image: linear-gradient(to bottom, #3e8bff, #c1a2fb);
}

.blueBackGroundGrandLtoR{
    background-image: linear-gradient(to right, #3e8bff, #c1a2fb);
}

.blueBackGroundGrandRtoL{
    background-image: linear-gradient(to left, #3e8bff, #c1a2fb);
}
 
@keyframes gradient {
    0% {
      color: #3e8bff;
    }
    50% {
      color: #c1a2fb;
    }
    100% {
      color: #3e8bff;
    }
  }
  
  .animate-gradient {
    animation: gradient 2s ease infinite;
  }