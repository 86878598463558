@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

header{
  
  background-color: rgb(23 23 23);
}

.mainLogo {
  width: 40px;
  height: 40px;
}










@media only screen and (max-width: 1024px){

  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    background-color: rgb(23 23 23);
    transition: 1s;
    transform: translateY(-100vh);
  }

  header .responsive_nav{
    transform: none;
  }

  nav .nav-close-btn{
    position: absolute;
    top: 20px;
    right: 0px;
    height: 40px;
    width: 40px;
  }
 
  nav a{
    font-size: 25px;
  }
}



@keyframes fill {
  0% { width: 0%; }
  100% { width: 100%; }
}

.animate-fill {
  animation: fill 2s linear; 
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }

  
}
